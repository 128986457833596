import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {ModuleInterface} from '../shared/ModuleInterface';
import {Subject} from 'rxjs';
import {AuthService} from './auth.service';

declare let io;

@Injectable()
export class SocketService {

  public module: ModuleInterface;
  private eventLeaflet2D = new Subject();

  protected socket;

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
    public authService: AuthService
  ) {
    this.module = new ModuleInterface('socket', this.httpClient, this.loaderService);
    this.module.addAuthorizationInAllRequest();
    this.connect();
  }

  connect(): void {
    this.socket = io(this.module.getBaseUrl());

    this.socket.on('SNAP_PROGRESSION', (data, msg) => {
      console.log('[SocketService] -> SNAP_PROGRESSION() -> ', data, msg);
      this.eventLeaflet2D.next({data, msg});
    });
    this.socket.on('SNAP_ERROR', (data, msg) => {
      console.log('[SocketService] -> SNAP_ERROR() -> ', data, msg);
      this.eventLeaflet2D.next({data, msg});
    });
    this.socket.on('SNAP_DONE', (data, msg) => {
      console.log('[SocketService] -> SNAP_DONE() -> ', data, msg);
      this.eventLeaflet2D.next({done: data});
    });
  }

  public getMap2DEventEmitter(): Subject<any> {
    return this.eventLeaflet2D;
  }

  public processMap(data: {
    bbox: any[],
    width: number,
    height: number,
    code_insee: string,
    echelle: any,
    list_layer_to_show: any[],
    namefile: string,
    icon: number,
    pcs_layer: string[]
  }): void {
    this.socket.emit('SNAP', data);
  }
}
