import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';


import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {LoaderService} from '../../services/loader.service';
import {PredictService} from '../../services/predict.service';
import {IgoService} from '../../services/igo.service';
import {SocketService} from '../../services/socket.service';
import {DownloadService} from '../../services/download.service';
import {SnapshotType} from '../entities/A0.entity';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MapperService} from '../../services/mapper.service';
import {capitalize, objectPositionToString, sortPriority, stringPositionToObject, toDegrees} from '../tools';
import {EnvironmentConfiguration} from '../EnvironmentConfiguration';
import {LocalParamsService} from '../../services/local-params.service';

import * as turf from '@turf/turf';
import {PlanEntity} from '../entities/plan.entity';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

interface EntityUpdate {
  entity: string;
  propName: string;
  value: any;
}

@Component({
  selector: 'app-map-cesium',
  template: `
    <mat-progress-bar
      *ngIf="layerProgress.all.active"
      [mode]="layerProgress.all.mode"
      [value]="layerProgress.all.pourcentage">
    </mat-progress-bar>

    <mat-progress-bar
      *ngIf="upload"
      [mode]="upload.state == 'PENDING' ? 'buffer' : 'determinate'"
      [value]="upload.progress"
    ></mat-progress-bar>

    <svg class="d-none" id="labelSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    </svg>


    <div class="d-flex justify-content-center align-items-center">
      <div class="d-block position-relative dragBoundary" #dragBoundary>
        <div class="map-container" #mapCesium ></div>

        <div class="map-north-arrow map-north-arrow-{{canvasMode}}" [style.transform]="rotate">
          <img src="../../assets/images/north-arrow.png" alt="Fleche nord">
        </div>

        <div class="map-echelle map-echelle-{{canvasMode}}">
          <span #echelleBar class="map-echelle_bar"></span>
          <span #echelleLabel class="map-echelle_label"></span>
        </div>

        <div *ngIf="isBuildingScreenShot | async" class="cesium-screenshot-loader">
          <div class="d-flex flex-column align-items-center">
            <mat-spinner [diameter]="50"></mat-spinner>
            <span class="bg-white p-2 mt-3 rounded-1" *ngIf="uploadLoadingMsg">{{uploadLoadingMsg}}</span>
          </div>
        </div>

        <div *ngIf="!!pickedObject?.primitive?.text" style="position:absolute;z-index: 5;top: 5px; right:5px; left:5px; padding: 15px; background-color: white">
          <div *ngIf="!!pickedObject.primitive.text">
            <b>Text :</b> {{pickedObject.primitive.text}}
          </div>
        </div>

        <ul id="cmenu_canvas" class="contextMenu">
          <li><a href="javascript:void(0)" (click)="enableMovePickedObject()" routerLink="./">Déplacer</a></li>
          <li class="separator"><a href="javascript:void(0)" (click)="deletePickedObject()" routerLink="./">Supprimer</a></li>
        </ul>

        <ng-content></ng-content>
      </div>
      <canvas #canvasSnapshot width="1500" height="1500" class="canvas-print"></canvas>
    </div>
  `,
  styles: [
    `.cesium-viewer-toolbar {display: none}`
  ]
})
export class MapCesiumComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('mapCesium') elementRef: ElementRef;

  constructor(
    private predictService: PredictService,
    private igoService: IgoService,
    private loader: LoaderService,
    private socketService: SocketService,
    private downloadService: DownloadService,
    private mapperService: MapperService,
    private cd: ChangeDetectorRef,
    private localParams: LocalParamsService,
    private snackbar: MatSnackBar,
  ) {

    this.$socket = this.socketService.getMap2DEventEmitter().subscribe((res) => {
      if (!res) {
        return;
      }


      if (typeof res.data === 'number') {
        this.uploadLoadingMsg = res.msg;
        this.upload = {
          progress: res.data,
          state: 'IN_PROGRESS'
        };
      }

      if (typeof res.data === 'string') {
        this.upload = null;

        this.feedback('danger', 'Une erreur est survenue')
      }

      if (!!res.done) {
        this.uploadLoadingMsg = '';
        this.feedback('success', 'le fichier 2d a été généré')
        this.upload = null;

        const screenshotOptions = {
          type: SnapshotType.MAIN,
          snapshot_url_image: res.done.url_hd,
          snapshot_url_image_lite: res.done.url_lite,
          position: '0,0',
          bbox: JSON.stringify(this.viewer.camera.computeViewRectangle())
        };

        this.mapperService.createOrUpdateSnapshot(this.plan, screenshotOptions).subscribe(res => {
          this.feedback('success', 'le fichier 2d a été généré');
          this.screenshotEvent.emit({type: 'success', data: screenshotOptions});
          this.isBuildingScreenShot.next(false);
        }, (err) => {
          this.feedback('danger', 'Une erreur est survenue');
          this.isBuildingScreenShot.next(false);
          this.screenshotEvent.emit({type: 'fail', data: screenshotOptions});
        });
      }
    });

    this.debouncedBuildPcs.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((iconSize) => {
      this.buildPCS(Number(iconSize));
    });
  }

  static INITIAL_POSITION = {
    ZOOM_1: '0,2835.4218',
    ZOOM_2: '0,6378.2118',
    MODULE_LEGENDE_STATIC: '0,0',
    MODULE_STRATEGIE_ALERTE_COMMUNALE: '0,1382.4793046357615',
  };

  DIMENSION_PRINT = {
    '2d': [13465, 9921],
    '3d': [1500, 1500],
  };

  upload;

  @Input() change: EventEmitter<any> = new EventEmitter();
  @Input() id = 'cesiumMap';
  @Input() insee = '34172';
  @Input() plan;
  @Input() canvasMode = '2d';
  @Input() type;
  @Input() snapshot;

  @Output() screenshotEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() isLoadingLayer: Subject<boolean> = new Subject<boolean>();

  @ViewChild('northArrow') northArrow;
  @ViewChild('echelleLabel') echelleLabel;
  @ViewChild('echelleBar') echelleBar;
  @ViewChild('canvasSnapshot') canvasSnapshot;

  public map;
  public viewer;
  public camera;
  public imageryLayers;
  public primitives;
  public listLayers = [];
  public pcs;
  public tilesToLoad;
  public layerProgress = {
    all: {
      value: 0,
      pourcentage: 0,
      max: 0,
      active: false,
      mode: 'indeterminate'
    }
  };
  public uploadLoadingMsg = '';

  public reloadFilter: EventEmitter<any> = new EventEmitter<any>();

  public iconSize = 20;
  public CITY_POLYGON;
  public CITY_RECTANGLE;
  public abstractCityPolygon;

  public debouncedBuildPcs = new Subject();

  public polygon = new Cesium.CustomDataSource();
  public zmi = new Cesium.GeoJsonDataSource();
  public zms = new Cesium.GeoJsonDataSource();
  public picto = new Cesium.CustomDataSource();
  public num = new Cesium.CustomDataSource();
  public building = new Cesium.CustomDataSource();
  public road = new Cesium.CustomDataSource();
  public railway = new Cesium.CustomDataSource();
  public hydroSurface = new Cesium.CustomDataSource();
  public watercourse = new Cesium.CustomDataSource();
  public places = new Cesium.CustomDataSource();

  public planEntities = new Map();

  private _layerList = {
    building: { url: 'https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&COUNT=10000&TYPENAME=BDTOPO_V3:batiment&BBOX='},
    road: { url: 'https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&TYPENAME=BDTOPO_V3:troncon_de_route&COUNT=10000&BBOX='},
    railway: { url: 'https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&TYPENAME=BDTOPO_V3:troncon_de_voie_ferree&COUNT=10000&BBOX='},
    hydroSurface: { url: 'https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&TYPENAME=BDTOPO_V3:surface_hydrographique&COUNT=10000&BBOX='},
    watercourse: { url: 'https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&TYPENAME=BDTOPO_V3:troncon_hydrographique&COUNT=10000&BBOX='},
    places: { url: 'https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&TYPENAME=BDTOPO_V3:toponymie_lieux_nommes&COUNT=10000&BBOX='}
  };

  zmiDistanceDisplayCondition = new Cesium.DistanceDisplayCondition(0, 50000);
  zmiColor = Cesium.Color.fromCssColorString('#80ffff').withAlpha(0.3);
  zmiColorMoyen = Cesium.Color.fromCssColorString('#4472c3').withAlpha(0.3);
  zmiColorFort = Cesium.Color.fromCssColorString('#002060').withAlpha(0.3);

  zmsColor = Cesium.Color.fromCssColorString('#dec8ee').withAlpha(0.3);
  zmsColorMoyen = Cesium.Color.fromCssColorString('#ae78d6').withAlpha(0.3);
  zmsColorFort = Cesium.Color.fromCssColorString('#7030a0').withAlpha(0.3);


  canvasWidth;
  canvasHeight;

  call = 0;
  callEnded = 0;
  batiLoadProgress = {
    value: 0,
    max: 0,
    mode: 'buffer',
    visible: false
  };
  layerToLoad = 0;
  rotate;
  pickedObject;

  batiMap = new Map();
  polyIdCacheMap = new Map();
  $socket: Subscription;

  moveHandler;
  dragging = false;

  public urlPictos =  EnvironmentConfiguration.urls.picto;

  viewModel;
  indexedLayer;
  baseLayers;

  echelle = {
    barWidth: null,
    distanceLabel: null
  };

  geodesic = new Cesium.EllipsoidGeodesic();

  distances = [
    1, 2, 3, 5,
    10, 20, 30, 50,
    100, 200, 300, 500,
    1000, 2000, 3000, 5000,
    10000, 20000, 30000, 50000,
    100000, 200000, 300000, 500000,
    1000000, 2000000, 3000000, 5000000,
    10000000, 20000000, 30000000, 50000000];

  @Output() whenMapIsInit: Subject<boolean> = new Subject();
  @Output() isMapLoadingTiles: Subject<boolean> = new Subject();
  @Output() isBuildingScreenShot: Subject<boolean> = new Subject();

  ngOnDestroy(): void {
    this.$socket.unsubscribe();
    this.debouncedBuildPcs.unsubscribe();
  }

  initMap(): Promise<any> {
    return new Promise((resolve) => {
      const map = new Cesium.Viewer(this.elementRef.nativeElement, {
        // imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
        //   url : 'https://data.geopf.fr/wmts?',
        //   layer : 'ORTHOIMAGERY.ORTHOPHOTOS',
        //   style : 'normal',
        //   format : 'image/jpeg',
        //   tileMatrixSetID : 'PM'
        // }),
        terrainProvider: Cesium.createWorldTerrain(),
        mapProjection: new Cesium.WebMercatorProjection(),
        timeline : false,
        animation : false,
        sceneMode: Cesium.SceneMode.SCENE3D,
        infoBox: false,
        selectionIndicator: false,
      });

      resolve(map);
    });
  }

  ngAfterViewInit(): void {
    this.initMap().then((map) => {
      this.map = map;

      this.viewer = this.map; // Generic alias
      this.camera = this.map.camera;


      this.imageryLayers = this.map.imageryLayers;
      this.primitives = this.map.scene.primitives;

      this.picto.num = this.num;

      this.viewer.dataSources.add(this.polygon);
      this.viewer.dataSources.add(this.zmi);
      this.viewer.dataSources.add(this.zms);
      this.viewer.dataSources.add(this.places);
      this.viewer.dataSources.add(this.building);
      this.viewer.dataSources.add(this.road);
      this.viewer.dataSources.add(this.railway);
      this.viewer.dataSources.add(this.hydroSurface);
      this.viewer.dataSources.add(this.watercourse);
      this.viewer.dataSources.add(this.num);
      this.viewer.dataSources.add(this.picto);

      this.picto.buildPCS = this.updateIconSize;
      this.picto.togglePicto = (alias) => {
        this.refreshActivePicto(alias);
      }

      this.watercourse.showLabels = true;
      this.watercourse.distanceBetweenLabels = 1500;
      this.watercourse.toggleLabels = () => {
        this.toggleLabels('watercourse');
      };

      this.watercourse.updateDistanceBetweenWatercourseLabels = (value) => {
        this.watercourse.distanceBetweenLabels = value;
        this.buildWatercourse();
      };

      this.picto.autoLoad = true;
      this.polygon.autoLoad = true;
      this.zmi.autoLoad = true;

      this.zms.autoLoad = true;
      this.zms.show = false;

      this.polygon.name = 'polygon';
      this.num.name = 'num';
      this.zmi.name = 'zmi';
      this.zms.name = 'zms';
      this.building.name = 'building';
      this.road.name = 'road';
      this.railway.name = 'railway';
      this.hydroSurface.name = 'hydroSurface';
      this.watercourse.name = 'watercourse';
      this.places.name = 'places';
      this.picto.name = 'picto';

      this.iconSize = this.localParams.getParamsHistory('iconSize-' + this.canvasMode) || 20;
      this.picto.initialSize = this.iconSize;

      this.setupIndexLayer([
        this.polygon,
        this.num,
        this.zmi,
        this.zms,
        this.building,
        this.road,
        this.railway,
        this.hydroSurface,
        this.watercourse,
        this.places,
        this.picto,
      ]);

      this.viewer.scene.globe.maximumScreenSpaceError = 1;

      this.viewer.scene.globe.tileLoadProgressEvent.addEventListener((tilesCount) => {
        if (this.tilesToLoad < tilesCount) {
          this.tilesToLoad = tilesCount;
          this.isMapLoadingTiles.next(true);
        }

        if (tilesCount === 0) {
          this.tilesToLoad = 0;
          this.isMapLoadingTiles.next(false);
        }

      });

      this.whenMapIsInit.next(true);
    });
  }

  setupIndexLayer(layerList) {
    this.indexedLayer = this.viewer.dataSources;

    this.viewModel = {
      layers: [],
      baseLayers: [],
      upLayer: null,
      downLayer: null,
      selectedLayer: null,
      isSelectableLayer: (layer) => {
        return this.baseLayers.indexOf(layer) >= 0;
      },
      raise: (layer, index) => {
        this.indexedLayer.raise(layer);
        this.viewModel.upLayer = layer;
        this.viewModel.downLayer = this.viewModel.layers[Math.max(0, index - 1)];
        this.updateLayerList();
        window.setTimeout(() => {
          this.viewModel.upLayer = this.viewModel.downLayer = null;
        }, 10);
      },
      lower: (layer, index) => {
        this.indexedLayer.lower(layer);
        this.viewModel.upLayer =
          this.viewModel.layers[
            Math.min(this.viewModel.layers.length - 1, index + 1)
            ];
        this.viewModel.downLayer = layer;
        this.updateLayerList();
        window.setTimeout(() => {
          this.viewModel.upLayer = this.viewModel.downLayer = null;
        }, 10);
      },
      canRaise: (layerIndex) => {
        return layerIndex > 0;
      },
      canLower: (layerIndex) => {
        return layerIndex >= 0 && layerIndex < this.indexedLayer.length - 1;
      },
    };

    this.updateLayerList();
  }

  updateLayerList(): void {
    const numLayers = this.indexedLayer.length;
    this.viewModel.layers.splice(0, this.viewModel.layers.length);
    for (let i = numLayers - 1; i >= 0; --i) {
      this.viewModel.layers.push(this.indexedLayer.get(i));
    }
  }


  closeContextMenu(): any {
    const shapeEditMenu = document.getElementById('cmenu_canvas');
    shapeEditMenu.style.display = 'none';
  }

  handleContextMenu(): any {
    const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);

    handler.setInputAction((click) => {
      this.dropPickedObject();

      const pickedObject = this.viewer.scene.pick(click.position);

      if (!!pickedObject?.primitive?._labelCollection || !!pickedObject?.primitive?._billboardCollection) {
        this.pickedObject = pickedObject;

        if (!!this.pickedObject.id.label) {
          this.pickedObject.id.label.backgroundColor = Cesium.Color.RED;
        }

        const shapeEditMenu = document.getElementById('cmenu_canvas');
        shapeEditMenu.style.display = 'block';
        shapeEditMenu.style.left = click.position.x + 'px';
        shapeEditMenu.style.top = click.position.y + 'px';

        handler.setInputAction(() => {
          this.dropPickedObject();
          this.closeContextMenu();
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      } else if (!this.dragging) {
        this.dropPickedObject();
        this.closeContextMenu();
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

  ngOnInit(): void {
    this.whenMapIsInit.subscribe((isInit) => {

      this.viewer.clock.onTick.addEventListener(() => {
        this.updateNorthArrow();
        this.updateDistanceLegendCesium({
          barWidth: 0,
          distanceLabel: '',
          enableDistanceLegend: true
        }, this.viewer.scene)
      });

      this.setMode(this.canvasMode);

      setTimeout(() => {
        this.getCityPolygon(this.insee);
        this.getPcs(this.insee);

        this.addZmi();
        this.addZms();
      }, 1100);
    });
  }

  updateNorthArrow(): any {
    const angle = this.viewer.camera.heading;
    const degree = angle * Cesium.Math.DEGREES_PER_RADIAN;

    this.rotate = `rotate(${-degree}deg)`;
  }

  updateDistanceLegendCesium(viewModel, scene) {
    if (!viewModel.enableDistanceLegend)
    {
      viewModel.barWidth = undefined;
      viewModel.distanceLabel = undefined;
      return;
    }
    const now = new Date().getTime();
    if (now < viewModel._lastLegendUpdate + 25000) {
      return;
    }

    viewModel._lastLegendUpdate = now;

    // Find the distance between two pixels at the bottom center of the screen.
    const width = scene.canvas.clientWidth;
    const height = scene.canvas.clientHeight;

    const left = scene.camera.getPickRay(new Cesium.Cartesian2((width / 2) | 0, height - 1));
    const right = scene.camera.getPickRay(new Cesium.Cartesian2(1 + (width / 2) | 0, height - 1));

    const globe = scene.globe;
    const leftPosition = globe.pick(left, scene);
    const rightPosition = globe.pick(right, scene);

    if (!Cesium.defined(leftPosition) || !Cesium.defined(rightPosition)) {
      viewModel.barWidth = undefined;
      viewModel.distanceLabel = undefined;
      return;
    }

    const leftCartographic = globe.ellipsoid.cartesianToCartographic(leftPosition);
    const rightCartographic = globe.ellipsoid.cartesianToCartographic(rightPosition);

    this.geodesic.setEndPoints(leftCartographic, rightCartographic);
    const pixelDistance = this.geodesic.surfaceDistance;

    // Find the first distance that makes the scale bar less than 100 pixels.
    const maxBarWidth = 100;
    let distance;
    for (var i = this.distances.length - 1; !Cesium.defined(distance) && i >= 0; --i) {
      if (this.distances[i] / pixelDistance < maxBarWidth) {
        distance = this.distances[i];
      }
    }

    if (Cesium.defined(distance)) {
      let label;
      if (distance >= 1000) {
        label = (distance / 1000).toString() + ' km';
      } else {
        label = distance.toString() + ' m';
      }

      viewModel.barWidth = (distance / pixelDistance) | 0;
      viewModel.distanceLabel = label;

      this.updateEchelleView(viewModel);
    } else {
      viewModel.barWidth = undefined;
      viewModel.distanceLabel = undefined;
    }
  }

  updateEchelleView(viewModel) {
    this.echelle = viewModel;

    if (this.echelleLabel.nativeElement.innerText !== viewModel.distanceLabel) {
      this.echelleLabel.nativeElement.innerText = viewModel.distanceLabel;
    }

    if (this.echelleBar.nativeElement.style.width !== viewModel.barWidth + 'px') {
      this.echelleBar.nativeElement.style.width = viewModel.barWidth + 'px';
    }
  }

  enableMovePickedObject(): any {
    this.moveHandler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
    this.closeContextMenu();
    this.moveHandler.setInputAction((click) => {
      const pickedObject = this.viewer.scene.pick(click.position);

      if (!!pickedObject && pickedObject.id?.id === this.pickedObject.id?.id) {
        this.dragging = true;
        this.viewer.scene.screenSpaceCameraController.enableRotate = false;

        this.moveHandler.setInputAction((movement) => {
          if (this.dragging) {
            this.pickedObject.id.position = this.viewer.camera.pickEllipsoid(movement.endPosition);
          }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
    this.moveHandler.setInputAction((movement) => {

      if (this.dragging) {
        this.dragging = false;
        this.viewer.scene.screenSpaceCameraController.enableRotate = true;

        this.disableMovePickedObject();
        this.dropPickedObject();
      }
    }, Cesium.ScreenSpaceEventType.LEFT_UP);

    return;
  }

  disableMovePickedObject(): any {
    if (this.moveHandler) {
      this.moveHandler.destroy();
      this.moveHandler = null;
    }
  }

  deletePickedObject(): any {
    this.pickedObject.id.show = false;
    this.closeContextMenu();
    this.dropPickedObject();
  }

  dropPickedObject(): any {
    if (!!this.pickedObject?.id?.label) {
      this.pickedObject.id.label.backgroundColor = Cesium.Color.WHITE;
    }

    this.pickedObject = null;
  }

  getCityPolygon(codeZone: string): any {
    this.predictService.getPolygon(codeZone).subscribe((data) => {

      this.viewer.camera.flyTo({
        destination: this.drawPolygon(data),
        duration: 0
      });

      if (!!this.snapshot && !!this.snapshot.bbox) {
        const view = JSON.parse(this.snapshot.bbox);

        if (this.type === 'MAIN') {
          this.viewer.camera.flyTo({
            destination: view
          });
        } else {
          this.viewer.camera.setView(view);
        }
      }

    });
  }

  getCamera(): any {
    const camera = this.viewer.scene.camera;
    const obj = {
      destination: camera.position,
      orientation: {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: camera.roll,
      }
    };

    return JSON.stringify(obj);
  }

  build2dScreenshot = (listLayer2DtoShow) => {
    this.isBuildingScreenShot.next(true);
    const rectangleCords = this.getCameraBoundingBox();
    const bbox = [rectangleCords.west, rectangleCords.south, rectangleCords.east, rectangleCords.north]
    const containerW = this.map.container.offsetWidth;
    const icon = Math.round(((5000 * 1.357) / containerW) * this.iconSize);
    this.processMap(bbox, icon, listLayer2DtoShow);
  }

  updateLayer(layerAlias, properties: EntityUpdate[]): void {
    this[layerAlias].entities.suspendEvents();
    this[layerAlias].entities.values.forEach(item => {
      properties.forEach(prop => {
        if (!!item[prop.entity]) {
          item[prop.entity][prop.propName] = prop.value;
        }
      });
    });
    this[layerAlias].entities.resumeEvents();
  }

  take3dScreenshot = (snapshot) => {
    this.isBuildingScreenShot.next(true);
    this.isLoadingLayer.next(true);


    const diffBetweenScreenAndScreenshotSize = (1500 * 100 / this.canvasWidth);

    const computedIconSize = (this.iconSize * diffBetweenScreenAndScreenshotSize) / 100;

    const echelleFixedValue = {
      ...this.echelle,
      barWidthComputed: (this.echelle.barWidth * diffBetweenScreenAndScreenshotSize) / 100
    };

    this.setMapCanvasSize(1, 1500);

    this.buildPCS(computedIconSize);

    [{
      name: 'places', properties: [{
        entity: 'label', propName: 'scale', value: 2
      }],
    }, {
      name: 'watercourse', properties: [{
        entity: 'label', propName: 'scale', value: 2
      }]
    }, {
      name: 'road', properties: [{
        entity: 'polyline', propName: 'width', value: 6
      }]
    }, {
      name: 'railway', properties: [{
        entity: 'polyline', propName: 'width', value: 6
      }]
    }, {
      name: 'watercourse', properties: [{
        entity: 'polyline', propName: 'width', value: 6
      }]
    }, {
      name: 'picto', properties: [{
        entity: 'polyline', propName: 'width', value: 6
      }]
    }].forEach((style) => this.updateLayer(style.name, style.properties));





    this.viewer.render();

    const screenshot = (event?) => {
      const baseScreenshot = this.viewer.canvas.toDataURL('image/jpeg', 1);

      ////// SCREENSHOT
      const ctx = this.canvasSnapshot.nativeElement.getContext('2d');
      ctx.clearRect(0, 0, this.canvasSnapshot.nativeElement.width, this.canvasSnapshot.nativeElement.height);
      const img = document.createElement('img');
      img.src = baseScreenshot;
      img.addEventListener('load', () => {
        /**
         * The actual map screenshot
         */
        ctx.drawImage(img, 0, 0);

        /**
         * The scale
         */
        const originLeft = 1500 - (echelleFixedValue.barWidthComputed + 150);

        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 0;

        // background
        ctx.beginPath();
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.rect(originLeft, 1500 - 50, echelleFixedValue.barWidthComputed + 150, 50);
        ctx.fill();
        ctx.closePath();

        // label
        ctx.fillStyle = 'black';
        ctx.textAlign = 'start';
        ctx.font = '30px Arial';
        ctx.fillText(echelleFixedValue.distanceLabel, originLeft + echelleFixedValue.barWidthComputed + 50, 1500 - 15);

        //// BAR@
        ctx.beginPath();
        ctx.fillStyle = 'black';
        //left
        ctx.rect(originLeft + 30, 1500 - 29, 3, 15);
        ctx.fill();
        //bottom
        ctx.rect(originLeft + 30, 1500 - 15, echelleFixedValue.barWidthComputed, 3);
        ctx.fill();
        //right
        ctx.rect(originLeft + 27 + echelleFixedValue.barWidthComputed, 1500 - 30, 3, 15);
        ctx.fill();
        ctx.closePath();


        /**
         * The arrow
         */
        const arrow = document.createElement('img');
        arrow.src = '../../assets/images/north-arrow.png';
        arrow.addEventListener('load', () => {
          const imgSize = 100;

          ctx.save();
          ctx.translate(1400 + imgSize / 2,  imgSize / 2);
          ctx.rotate(-this.viewer.camera.heading);
          ctx.drawImage(arrow, imgSize / 2 * (-1), imgSize / 2 * (-1), imgSize, imgSize);
          ctx.restore();


          const finalScreenshot = this.canvasSnapshot.nativeElement.toDataURL('image/jpeg', 1);
          const blob = this.downloadService.dataURItoBlob(finalScreenshot);

          this.downloadService.uploadSnapshot(blob, this.plan.id, snapshot.type).subscribe((res) => {
            this.upload = res;

            if (res.state === 'DONE' && !!res.content.url) {
              this.upload = null;

              this.mapperService.createOrUpdateSnapshot(this.plan, {
                ...snapshot,
                type: snapshot.type,
                snapshot_url_image: res.content.url,
                position: MapCesiumComponent.INITIAL_POSITION[snapshot.type],
                bbox: this.getCamera()
              }).subscribe(() => {
                this.setMapCanvasSize(1, document.body.clientHeight * 0.80);

                [{
                  name: 'places', properties: [{
                    entity: 'label', propName: 'scale', value: 1
                  }],
                }, {
                  name: 'watercourse', properties: [{
                    entity: 'label', propName: 'scale', value: 1
                  }]
                }, {
                  name: 'road', properties: [{
                    entity: 'polyline', propName: 'width', value: 4
                  }]
                }, {
                  name: 'railway', properties: [{
                    entity: 'polyline', propName: 'width', value: 4
                  }]
                }, {
                  name: 'watercourse', properties: [{
                    entity: 'polyline', propName: 'width', value: 4
                  }]
                }, {
                  name: 'picto', properties: [{
                    entity: 'polyline', propName: 'width', value: 4
                  }]
                }].forEach((style) => this.updateLayer(style.name, style.properties));

                this.updateIconSize(this.iconSize);
                if (event) {
                  event.unsubscribe();
                }

                this.snackbar.open('Le zoom a été sauvegardé', 'ok', {
                  duration: 3000
                });

                this.isLoadingLayer.next(false);
                this.isBuildingScreenShot.next(false);
              }, () => {
                this.setMapCanvasSize(1, document.body.clientHeight * 0.80);
                this.updateIconSize(this.iconSize);
                this.isLoadingLayer.next(false);
                this.isBuildingScreenShot.next(false);
                this.feedback('danger', `Un problème est survenue pendant l'envoies du fichier`);
              });
            }
          }, (err) => {
            this.setMapCanvasSize(1, document.body.clientHeight * 0.80);
            this.updateIconSize(this.iconSize);
            this.isLoadingLayer.next(false);
            this.isBuildingScreenShot.next(false);
            this.feedback('danger', `Un problème est survenue pendant l'envoies du fichier`);
          });
        });
      });
    };

    if (this.viewer.scene.globe.tilesLoaded) {
      setTimeout(() => {
        // pour éviter de capturer l'image si tout les tiles sont déjà charger avant que le
        // layers pcs ne soit refresh
        screenshot();
      }, 1000);
    } else {
      const subscription = this.isMapLoadingTiles.subscribe((isLoading) => {
        if (!isLoading) {
          screenshot(subscription);
        }
      });
    }
  }

  feedback(type, msg): void {
    this.snackbar.open(msg, null, {
      duration: 3000
    });
  }

  pointInsideBbox(points, bbox): boolean {
    const [minX, minY, maxX, maxY] = bbox;
    const [x, y] = points;

    return x >= minX && x <= maxX && y >= minY && y <= maxY;
  }

  filterSelectedMarkers(bbox) {
    const selectedEntity = [];

    Array.from(this.planEntities.values()).forEach((entity) => {
      if (entity.show && entity.points.some((p) => this.pointInsideBbox(toDegrees(p.position._value), bbox)) && !!entity.num
      ) {
        selectedEntity.push(entity.num);
      }
    });

    return Array.from(new Set(selectedEntity));
  }

  handleNiveauLayer(listActiveLayer: Array<string>): Array<string> {
    const showNiveauLayer = [];

    listActiveLayer.forEach((layerName) => {
      if (this[layerName].showNiveau) {
        showNiveauLayer.push(`${layerName}_niveau`);
      }
    });

    return showNiveauLayer;
  }

  processMap(bbox, icon, listLayer2DtoShow): void {
    const pointsActive = this.filterSelectedMarkers(bbox);

    const showNiveauLayer = this.handleNiveauLayer(listLayer2DtoShow);

    const diffBetweenScreenAndScreenshotSize = (6785 / this.canvasWidth);

    const hide_label_from_layers = [];

    const options = {
      icon, bbox,
      width: 6785,
      height: 5000,
      echelle: {
        label: this.echelle.distanceLabel,
        barWidth: (this.echelle.barWidth * diffBetweenScreenAndScreenshotSize),
      },
      hide_label_from_layers,
      watercourse: this.watercourse.show ? {
        distanceBetweenLabels: this.watercourse.distanceBetweenLabels,
      } : null,
      code_insee: this.insee,
      list_layer_to_show: listLayer2DtoShow,
      show_niveau_layer: showNiveauLayer,
      namefile: this.insee + '-map-2d.jpg',
      pcs_layer: pointsActive
    };

    listLayer2DtoShow.forEach((layerName) => {
      if (!!this[layerName].toggleLabels && !this[layerName].showLabels) {
        hide_label_from_layers.push(layerName);
      }
    });

    this.socketService.processMap(options);
  }

  getPcs(insee): any {
    this.igoService.getPcs(insee).subscribe((pcs) => {
      this.pcs = pcs;

      this.buildPCS();

      setTimeout(() => {
        // les recharger un peut après semble 'fixer" le problème du pcs invisible......
        this.buildPCS();
      }, 2000);
    });
  }

  updateIconSize = (size, dontSave?) => {
    if (!dontSave) {
      this.iconSize = size;
      this.localParams.setParamsHistory('iconSize-' + this.canvasMode, size);
    }

    this.debounceBuildPcs(size);
  }

  debounceBuildPcs(iconSize): void {
    this.debouncedBuildPcs.next(iconSize);
  }

  buildPCS = (size = this.iconSize) => {
    this.picto.entities.removeAll();
    this.num.entities.removeAll();

    this.planEntities.clear();

    let pixelOffset;
    let scale;
    let lineWidth;

    this.picto.entities.suspendEvents();
    this.num.entities.suspendEvents();

    let displayCondition = {};

    if (this.canvasMode === '3d') {
      pixelOffset = new Cesium.Cartesian2(-(size / 2), -(size / 20));
      scale = 1;
      lineWidth = 4;
      displayCondition = {
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
      };
    } else {
      pixelOffset = new Cesium.Cartesian2(-3, -(size / 20));
      scale = .4;
      lineWidth = 1;
    }

    const colorLine = { fermeture_route: Cesium.Color.RED, itineraire_evacuation: Cesium.Color.LIME };

    const features = [...this.pcs.lines?.features, ...this.pcs.points?.features];

    const hasPriority = features.some(feature => feature.properties.priority !== 'Faible');

    features.forEach(feature => {

      let entity = null;

      if (this.planEntities.has(feature.properties.fid)) {
        entity = this.planEntities.get(feature.properties.fid);
      } else {
        entity = new PlanEntity(feature.properties.alias);

        entity.setFilter('CATEGORIE', feature.properties.alias, true)

        entity.name = feature.properties.alias;

        entity.setFilter('PRIORITÉ', feature.properties.priority && feature.properties.type_pcs !== 'moyens'
            ? hasPriority ? `${feature.properties.priority}` : 'Enjeux et actions'
            : 'Moyens et gestion de crise',
          true,
          sortPriority(feature.properties.priority));

        if (feature.properties.competence) {
          entity.setFilter('COMPÉTENCE', feature.properties.competence, true);
        }

        if (feature.properties.type_alea) {
          entity.setFilter('TYPE ALÉA', feature.properties.type_alea, true);
        }

        this.planEntities.set(feature.properties.fid, entity);
      }

      entity.num = feature.properties.num;

      if (feature.geometry.type === 'LineString') {
        const layer = this.picto.entities.add({
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray(feature.geometry.coordinates.flat(5)),
            clampToGround: true,
            material: colorLine[feature.properties.alias],
            width: lineWidth,
          },
          properties: {
            alias: feature.properties.alias
          },
          show: entity.show
        });

        entity.addLayer(feature.geometry.type, layer);
      }

      if (feature.geometry.type === 'Point') {

        this.drawSvg(feature.properties.num, feature.properties.priority).then(img => {

          const layer = this.num.entities.add({
            position : Cesium.Cartesian3.fromDegrees(
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ),
            billboard : {
              image: img,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              height: size * 0.7,
              width: size * 0.7,
              eyeOffset:  new Cesium.Cartesian3( 0, 0, 20 ),
              ...displayCondition,
              pixelOffset,
              scale
            },
            properties: {
              alias: feature.properties.alias
            },
            show: entity.show
          });

          entity.addLayer(feature.geometry.type, layer);
        });
        let bordure = '';
        if (feature.properties.priority === 'Elevée') {
          bordure = `?has_border=true&border=f1c40f&border_width=20`;
        }
        const iconUrl = `${this.urlPictos}picto/plan/${feature.properties.alias}/40.png${bordure}`;

        const layer = this.picto.entities.add({
          position : Cesium.Cartesian3.fromDegrees(
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ),
          billboard : {
            image : iconUrl,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            height: size,
            width: size,
            pixelOffset: new Cesium.Cartesian2(0, 0),
            eyeOffset:  new Cesium.Cartesian3( 0, 0, 30  ),
            ...displayCondition,
            scale
          },
          properties: {
            alias: feature.properties.alias
          },
          show: entity.show
        });

        entity.addLayer(feature.geometry.type, layer);
      }
    });

    this.reloadFilter.next(true);
    this.picto.entities.resumeEvents();
    this.num.entities.resumeEvents();
  }

  toggleLabels(layerName): void {
    this[layerName].entities.values.forEach((item) => {
      if (item?.label) {
        item.label.show = this[layerName].showLabels;
      }
    });
  }

  refreshActivePicto(alias): void {
    this.picto.entities.suspendEvents();
    this.num.entities.suspendEvents();

    const toggleFun = (item) => {
      if (item?.properties?.alias && item.properties.alias.valueOf() === alias) {
        item.show = !item.show;
      }
    };

    this.picto.entities.values.forEach(toggleFun);
    this.num.entities.values.forEach(toggleFun);
    this.picto.entities.resumeEvents();
    this.num.entities.resumeEvents();
  }

  createNode(n, v): any {
    n = document.createElementNS("http://www.w3.org/2000/svg", n);
    for (var p in v)
      n.setAttribute(p, v[p]);
    return n
  }

  drawSvg(num, priority): any {
    return new Promise((resolve) => {
      const svg = this.createNode('svg', {
        "width": 100,
        "height": 100,
        viewBox: '0 0 100 100',
        xmlns: "http://www.w3.org/2000/svg"
      });
      let color = '#000';
      if (priority === 'Elevée') {
        color = '#f1c40f' ;
      }

      const circle = this.createNode('circle', {
        cy: '50',
        cx: '50',
        r: '50',
        fill: color,
      });

      let colorText = '#FFF';
      if (priority === 'Elevée') {
        colorText = '#000';
      }

      const number = this.createNode('text', {
        "font-weight": "bold",
        "text-anchor": "start",
        "font-family": "Roboto, Helvetica, Arial, sans-serif",
        "font-size": "50",
        "y": "65",
        "x": num.length > 2 ? '5' : num.length > 1 ? '23' : '35',
        "fill": colorText,
      });

      number.innerHTML = num;

      svg.append(circle);
      svg.append(number);

      const outerHTML = svg.outerHTML;
      const blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        const img: any = new Image();
        img.src = base64data;

        resolve(img);
      };
    });
  }

  previouslyLoadedZmi = [];
  previouslyLoadedZms = [];

  addZmi(): void {
    this.viewer.camera.moveEnd.addEventListener(() => {
      this.buildZmi();
    });
  }

  addZms(): void {
    this.viewer.camera.moveEnd.addEventListener(() => {
      this.buildZms();
    });
  }

  setBatiLoading(isLoading?): void {
    if (isLoading) {
      this.batiLoadProgress.visible = true;
      this.batiLoadProgress.mode = 'buffer';
      this.building.entities.suspendEvents();
    } else {
      this.batiLoadProgress.visible = false;
      this.building.entities.resumeEvents();
    }
  }

  public lavetorMap = new Map();

  public computeStripeRepeat(surface: number): number {
    let repeat = 0;
    if (surface > 1000000) {
      repeat = surface / 4200;
    } else if (surface > 100000) {
      repeat = surface / 1200;
    } else if (surface > 10000) {
      repeat = surface / 100;
    } else if (surface > 1000) {
      repeat = surface / 10;
    } else {
      repeat = surface;
    }

    return repeat;

  }


  createStripeMaterial(entity: any): any {
    return new Cesium.StripeMaterialProperty({
      evenColor: this.zmiColor,
      oddColor: Cesium.Color.TRANSPARENT,
      repeat: this.computeStripeRepeat(entity.properties.surface),
      offset: 0
    });
  }

  buildZmi(): void {
    const distance = this.distanceVisibleInCamera();

    if (!distance || distance > 41) {
      return;
    }

    const bbox = this.toBBoxString(this.getCameraBoundingBox());

    if (this.previouslyLoadedZmi.includes(bbox)) {
      return;
    }

    this.predictService.getZmi(bbox).subscribe((geojson) => {

      this.previouslyLoadedZmi.push(bbox);

      geojson.features.map((item) => {
        if (item.properties.type_alea === 'LAVE TORRENTIELLE') {
          this.lavetorMap.set(item.properties.id, item);
        }
      });

      const zmi = geojson.features.filter((item) => item.properties.type_alea !== 'LAVE TORRENTIELLE');
      let laveTorFeatures = [];
      const _laveTorPolygons = [];

      if (this.lavetorMap.size > 0) {
        this.lavetorMap.forEach((item) => {
          _laveTorPolygons.push(...item.geometry.coordinates);
        });

        const union = _laveTorPolygons.reduce((acc, item) => {
          return turf.union(acc, turf.polygon([item]));
        }, turf.polygon([_laveTorPolygons[0]]));

        if (union.geometry.type === 'Polygon') {
          laveTorFeatures.push(union);
        } else {
          laveTorFeatures.push(...union.geometry.coordinates.map((item) => turf.polygon(item)));
        }

        laveTorFeatures = laveTorFeatures.map((item) => ({
          ...item,
          properties: {
            ...item.properties,
            type_alea: 'LAVE TORRENTIELLE',
            surface: turf.area(item)
          }
        }));
      }

      try{
        this.zmi.load({
          type: 'FeatureCollection',
          features: [...zmi, ...laveTorFeatures]
        }, {
          stroke: this.zmiColor,
          fill: this.zmiColor,
          strokeWidth: 3,
          markerSymbol: '?',
          clampToGround: true,
          distanceDisplayCondition: this.zmiDistanceDisplayCondition
        }).then ( (e) => {
          e.entities.values.forEach( (item) => {
            if (item.properties.type_alea.getValue() === 'LAVE TORRENTIELLE'){
              item.polygon.material = this.createStripeMaterial(item);
              item.polygon.stRotation = Cesium.Math.toRadians(45);
            } else if (item.properties.type_alea.getValue() === 'LAVE TORRENTIELLE BORDER') {
              item.polyline.stroke = Cesium.Color.AQUA.withAlpha(1);
            } else if (item.properties.niveau.getValue() === 'FORT' && this.zmi.showNiveau) {
              item.polygon.material = this.zmiColorFort;
            } else if (item.properties.niveau.getValue() === 'MOYEN' && this.zmi.showNiveau) {
              item.polygon.material = this.zmiColorMoyen;
            }
          });
        });

      } catch (error){
        console.log(error)
      }

    });
  }

  buildZms(): void {
    const distance = this.distanceVisibleInCamera();

    if (!distance || distance > 41) {
      return;
    }

    const bbox = this.toBBoxString(this.getCameraBoundingBox());

    if (this.previouslyLoadedZms.includes(bbox)) {
      return;
    }

    this.predictService.getZms(bbox).subscribe((geojson) => {
      this.previouslyLoadedZms.push(bbox);

      try{
        this.zms.load(geojson, {
          stroke: this.zmsColor,
          fill: this.zmsColor,
          strokeWidth: 3,
          markerSymbol: '?',
          clampToGround: true,
          distanceDisplayCondition: this.zmiDistanceDisplayCondition
        }).then ( (e) => {
          e.entities.values.forEach( (item) => {
            if (item.properties.niveau.getValue() === 'FORT' && this.zms.showNiveau) {
              item.polygon.material = this.zmsColorFort;
            } else if (item.properties.niveau.getValue() === 'MOYEN' && this.zms.showNiveau) {
              item.polygon.material = this.zmsColorMoyen;
            }
          });
        });
      } catch (error){
        console.log(error);
      }
    });
  }

  distanceVisibleInCamera(): any {
    const {west, north, east, south} = this.getCameraBoundingBox();

    const startCartesian3Point = Cesium.Cartesian3.fromDegrees(north, west);
    const endCartesian3Point = Cesium.Cartesian3.fromDegrees(south, east);

    const startCartographicPoint = Cesium.Cartographic.fromCartesian(startCartesian3Point);
    const endCartographicPoint = Cesium.Cartographic.fromCartesian(endCartesian3Point);

    const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(startCartographicPoint,
      endCartographicPoint );
    const distance = ellipsoidGeodesic.surfaceDistance;

    return distance * 0.001;
  }

  loadAllLayer(layerAlias: string[]): void {
    this.layerToLoad = layerAlias.length;
    const bbox = this.toIGNBBoxString(this.getCameraBoundingBox());

    this.isLoadingLayer.next(true);

    this.layerProgress.all.max = 0;
    this.layerProgress.all.value = 0;
    this.layerProgress.all.active = true;
    this.layerProgress.all.mode = 'indeterminate';

    layerAlias.forEach(layer => {
      this[layer].entities.suspendEvents();

      fetch(this._layerList[layer].url + bbox)
        .then(res => res.json())
        .then(res => {

          this.layerProgress[layer] = {};
          this.layerProgress[layer].max = 0;
          this.layerProgress[layer].value = 0;

          this.buildOrDivide(res, bbox, layer);
        });
    });
  }

  buildOrDivide(res, bbox, layerAlias): any {
    const dontDivideLayer = ['places'];

    if (res.numberMatched >= 1000 && !dontDivideLayer.includes(layerAlias)) {

      const cleanedBbox = typeof bbox === 'string' ? bbox.split(',').map(a => Number(a)) : bbox;
      const bboxArray = this.divideBbox(cleanedBbox,
        Math.sqrt(res.numberMatched / 1000) * 3);

      bboxArray.forEach((_bbox) => {
        fetch(this._layerList[layerAlias].url + _bbox.join(',')).then(raw => raw.json()).then(_res => {
          this.buildOrDivide(_res, _bbox, layerAlias);
        });
      });

      this.layerProgress.all.max += bboxArray.length;
      this.layerProgress[layerAlias].max += bboxArray.length;

      if (layerAlias === 'building') {
        this.layerProgress.all.value += 1;
        this.layerProgress['building'].value += 1;
      }
    } else {
      switch (layerAlias) {
        case 'building':
          this.buildBatiment(res);
          break;
        case 'road':
          this.buildRoad(res);
          break;
        case 'railway':
          this.buildRailway(res);
          break;
        case 'watercourse':
          this.buildWatercourse(res);
          break;
        case 'hydroSurface':
          this.buildHydroSurface(res);
          break;
        case 'places':
          this.buildPlaces(res);
          break;
      }

      this.layerProgress.all.value += 1;
      this.layerProgress.all.pourcentage = this.layerProgress.all.value * 100 / this.layerProgress.all.max;
      this.layerProgress.all.mode = 'determinate';

      this.layerProgress[layerAlias].value += 1;

      if (this.layerProgress[layerAlias].max === 0) {
        this.layerProgress[layerAlias].max += 1;
        this.layerProgress.all.max += 1;
      }

      if (this.layerProgress[layerAlias].value === this.layerProgress[layerAlias].max) {
        this[layerAlias].entities.resumeEvents();
      }

      if (this.layerProgress.all.value >= this.layerProgress.all.max && this.layerProgress.all.max >= this.layerToLoad) {
        this.layerProgress.all.active = false;
        this.isLoadingLayer.next(false);
        setTimeout(() => {
          this.viewer.render();
        });
      }
    }
  }

  toBBoxString(rectangleCords): any {
    return rectangleCords.west + ',' + rectangleCords.north + ',' + rectangleCords.east + ',' + rectangleCords.south;
  }

  toIGNBBoxString(rectangleCords): any {
    return `${rectangleCords.south},${rectangleCords.west},${rectangleCords.north},${rectangleCords.east}`
  }

  set3DBuildingMaterial(nature, opacity): any {
    switch (nature) {
      case 'Industriel': {
        return {
          material: Cesium.Color.LIGHTGRAY.withAlpha(opacity),
          outlineColor: Cesium.Color.LIGHTGRAY.darken(0.2, new Cesium.Color()),
          outline: true
        };
      }
      case 'Religieux': {
        return {
          material: Cesium.Color.HOTPINK.withAlpha(opacity),
          outlineColor: Cesium.Color.HOTPINK.darken(0.2, new Cesium.Color()),
          outline: true
        };
      }
      case 'Agricole': {
        return {
          material: Cesium.Color.YELLOW.withAlpha(opacity),
          outlineColor: Cesium.Color.YELLOW.darken(0.2, new Cesium.Color()),
          outline: true
        };
      }
      case 'Sportif': {
        return {
          material: Cesium.Color.LIMEGREEN.withAlpha(opacity),
          outlineColor: Cesium.Color.LIMEGREEN.darken(0.2, new Cesium.Color()),
          outline: true
        };
      }
      default: {
        return {
          material: Cesium.Color.GHOSTWHITE.withAlpha(opacity),
          outlineColor: Cesium.Color.GHOSTWHITE.darken(0.2, new Cesium.Color()),
          outline: true
        };
      }
    }
  }

  bboxStringToBboxArray(bbox): any[] {
    return bbox.split((','));
  }

  reverseBboxArray(array): any {
    return [array[1], array[0], array[3], array[2]];
  }

  multiPolygonToCartesianPolygonHierarchies(arr): any {
    const deepCopy = JSON.parse(JSON.stringify(arr));
    const result = [];

    deepCopy.forEach(a => {
      a.forEach(b => {
        result.push({positions: b.map(c => Cesium.Cartesian3.fromDegreesArray(c)).flat()})
      });
    });

    return result;
  }

  handleMultiLineString(arr): any[] {
    const deepCopy = JSON.parse(JSON.stringify(arr));
    const result = [];

    deepCopy.forEach(a => {
      result.push({positions: a.map(c => Cesium.Cartesian3.fromDegreesArray(c)).flat()})
    });

    return result;
  }

  bboxToRectangle(bbox): any[] {
    return [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[1]],
      [bbox[2], bbox[3]],
      [bbox[0], bbox[3]],
    ];
  }

  imageryMaxBox(): any {
    const MAPBOX_TOKEN = 'pk.eyJ1IjoicHNvbHVibGUiLCJhIjoiY2tnd2FrZG84MDhhbDJ6b2ZidGo5dm1reCJ9.-iQaUIufcS6Q2XJyJxGuCw';

    return new Cesium.ProviderViewModel({
      name : 'Mapbox',
      iconUrl : Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/mapboxSatellite.png'),
      creationFunction : () => {
        return new Cesium.UrlTemplateImageryProvider({
          url: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}`,
          tileWidth: 512,
          tileHeight: 512
        });
      }
    });
  }

  getCameraBoundingBox(): any {
    const rectangle = this.viewer.camera.computeViewRectangle();

    if (!rectangle) {
      return {
        west: 0,
        north: 0,
        east: 0,
        south: 0,
      };
    }

    return {
      west: +Cesium.Math.toDegrees(rectangle.west).toFixed(4),
      north: +Cesium.Math.toDegrees(rectangle.north).toFixed(4),
      east: +Cesium.Math.toDegrees(rectangle.east).toFixed(4),
      south: +Cesium.Math.toDegrees(rectangle.south).toFixed(4),
    };
  }

  divideBbox(bbox, nCut): any {
    const pasX = (bbox[2] - bbox[0]) / nCut;
    const pasY = (bbox[3] - bbox[1]) / nCut;
    const list = [];

    for (let xInc = 0; xInc < nCut; xInc++) {
      for (let yInc = 0; yInc < nCut; yInc++) {
        const xMin = bbox[0] + pasX * xInc;
        const yMin = bbox[1] + pasY * yInc;
        const xMax = xMin + pasX;
        const yMax = yMin + pasY;
        list.push([xMin, yMin, xMax, yMax]);
      }
    }

    return list;
  }

  buildRoadFromBbox = (bbox = this.toIGNBBoxString(this.getCameraBoundingBox())) => {
    const geoJsonPromise = fetch('https://data.geopf.fr/wfs?version=2.0.0&SERVICE=WFS&REQUEST=GetFeature&OUTPUTFORMAT=json&TYPENAME=BDTOPO_V3:troncon_de_route&COUNT=10000&BBOX=' + bbox).then(res => res.json())

    geoJsonPromise.then(res => {
      this.buildRoadOrDivide(res, bbox)
    });
  }

  buildPlaces(res): void {
    if (res?.features.length) {
      res.features.forEach(place => {
        if (!this.places.entities.getById(place.id)) {
          const textStyle = this.canvasMode === '2d' ? {
            font: `2px sans-serif`,
            backgroundPadding: new Cesium.Cartesian2(2, 1),
          } : {
            font: `7px sans-serif`,
            backgroundPadding: new Cesium.Cartesian2(3, 2),
          };

          this.places.entities.add({
            id: place.id,
            position : Cesium.Cartesian3.fromDegrees(
              place.geometry.coordinates[0],
              place.geometry.coordinates[1],
            ),
            label : {
              text : capitalize(place.properties.graphie_du_toponyme),
              disableDepthTestDistance: 2000,
              fillColor: Cesium.Color.GREEN,
              backgroundColor: Cesium.Color.WHITE,
              showBackground: true,
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              eyeOffset:  new Cesium.Cartesian3( 0, 0, -200 ),
              ...textStyle
            }
          });
        }
      });
    }
  }

  // function that take an array of latlng and return a filtered array of latlng with at least 5kilimeters of distance between the point (using turf rhumbDistance)
  keepOnlyOutside(array: any[], kmRange): any[] {
    const result = [];
    let lastPoint = array[0];

    array.forEach((point) => {
      if (turf.rhumbDistance(lastPoint, point, {units: 'meters'}) > kmRange) {
        result.push(point);
        lastPoint = point;
      }
    });

    return result;
  }


  buildWatercourse(res?): void {
    this.watercourse.entities.removeAll();

    const watercourseMap = new Map();
    const radius = this.watercourse.distanceBetweenLabels;
    const textStyle = this.canvasMode === '2d' ? {
      font: `2px sans-serif`,
      backgroundPadding: new Cesium.Cartesian2(2, 1),
    } : {
      font: `7px sans-serif`,
      backgroundPadding: new Cesium.Cartesian2(3, 2),
    };

    const geojson = res || this.watercourse.previousGeojson;

    geojson?.features?.forEach(watercourse => {
      // On ne prend pas en compte la hauteur (z) des coordonnées
      watercourse.geometry.coordinates = watercourse.geometry.coordinates.map(a => a.slice(0, 2));

      const coordinates = watercourse.geometry.coordinates;
      const watercourseName = watercourse.properties.cpx_toponyme_de_cours_d_eau;

      // regroupement des coordonnées (LineString) par nom de cours d'eau pour l'affichage des labels
      if (watercourseMap.has(watercourseName)) {
        watercourseMap.get(watercourseName).push(coordinates);
      } else {
        // On ne prend pas en compte les cours d'eau sans nom
        if (watercourseName) {
          watercourseMap.set(watercourseName, [coordinates]);
        }
      }

      // Render des LineString
      this.watercourse.entities.add({
        id: 'line_' + watercourse.id ,
        polyline: {
          positions: watercourse.geometry.coordinates.map((a) => Cesium.Cartesian3.fromDegreesArray(a)).flat(),
          clampToGround: true,
          material: Cesium.Color.ROYALBLUE,
          width: 4,
          arcType: Cesium.ArcType.RHUMB
        },
      });
    });


    watercourseMap.forEach((lineStringArray, watercourseName) => {

      const filteredLatLngList = lineStringArray
        .flat() // on prend les coordonnées de chaque LineString
        .sort((a, b) => a[0] - b[0]) // on trie par longitude
        .reduce((acc, item) => { // on garde uniquement les points qui sont à plus de (x) mètres de distance
          if (acc.length === 0) {
            acc.push(item);
          } else if (acc.every((a) => turf.rhumbDistance(a, item, {units: 'meters'}) > radius)) {
            acc.push(item);
          }
          return acc;
        }, []);

      // Render des labels
      filteredLatLngList.forEach((coord) => {
        this.watercourse.entities.add({
          position: Cesium.Cartesian3.fromDegrees(...coord),
          label: {
            text: watercourseName,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.ROYALBLUE,
            showBackground: true,
            backgroundColor: Cesium.Color.WHITE,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            ...textStyle
          }
        });
      });
    });

    this.watercourse.previousGeojson = geojson;
  }

  buildHydroSurface(res): void {
    if (res?.features.length) {
      res.features.forEach(hydro => {
        if (!this.hydroSurface.entities.getById(hydro.id)) {
          this.hydroSurface.entities.add({
            id: hydro.id,
            polygon: {
              material: Cesium.Color.BLUE.withAlpha(.2),
              hierarchy: {
                positions: Cesium.Cartesian3.fromDegreesArray(this.normalizeMultipolygon(hydro.geometry.coordinates).flat(5))
              },
            }
          });
        }
      });
    }
  }

  buildRoad(res): void {
    if (res?.features.length) {
      res.features.forEach(road => {
        if (!this.road.entities.getById(road.id)
          && (road.properties.cpx_classement_administratif === 'Départementale'
            || road.properties.cpx_classement_administratif === 'Nationale' || road.properties.cpx_classement_administratif === 'Route intercommunale'
            || road.properties.cpx_classement_administratif === 'Autoroute' )){
          this.road.entities.add({
            id: road.id,
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(road.geometry.coordinates.flat(5)),
              clampToGround: true,
              material: (road.properties.cpx_classement_administratif === 'Départementale'
                || road.properties.cpx_classement_administratif === 'Route intercommunale' || road.properties.cpx_classement_administratif === 'Nationale')
                ? new Cesium.PolylineOutlineMaterialProperty({
                  color: Cesium.Color.ORANGE.withAlpha(0.8),
                  outlineColor: Cesium.Color.BLACK.withAlpha(0.8),
                  outlineWidth: 1
                })
                : new Cesium.PolylineOutlineMaterialProperty({
                  color: Cesium.Color.RED.withAlpha(0.8),
                  outlineColor: Cesium.Color.BLACK.withAlpha(0.8),
                  outlineWidth: 1
                }),
              width: 4
            }
          });
        }
      });
    }
  }

  buildRailway(res): void {
    if (res?.features.length) {
      res.features.forEach(road => {
        if (!this.railway.entities.getById(road.id)) {
          this.railway.entities.add({
            id: road.id,
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(road.geometry.coordinates.flat()),
              clampToGround: true,
              material: new Cesium.PolylineOutlineMaterialProperty({
                color: Cesium.Color.BROWN.withAlpha(0.8),
                outlineColor: Cesium.Color.BLACK.withAlpha(0.8),
                outlineWidth: 1
              }),
              width: 4
            }
          });
        }
      });
    }
  }

  buildRoadOrDivide = (res, bbox) => {
    if (res.numberMatched >= 1000) {
      const bboxArray = this.divideBbox(bbox.split(',').map(a => Number(a)), Math.sqrt(res.numberMatched / 1000 * 2));

      bboxArray.forEach((_bbox, index) => {
        this.buildRoadFromBbox(_bbox.join(','));
      });
    } else {
      this.buildRoad(res);
    }
  }

  buildBatiment(res): void {
    if (res?.features.length) {
      res.features.forEach(bati => {
        if (!this.building.entities.getById(bati.id)) {

          this.batiMap.set(bati.id, bati);

          this.building.entities.add({
            id: bati.id,
            polygon: {
              hierarchy: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(bati.geometry.coordinates.flat(5))
              },
              extrudedHeight: bati.properties.hauteur,
              height: 0,
              closeBottom: true,
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              ...this.set3DBuildingMaterial(bati.properties.usage_1, .75),
            }
          });
        }
      });
    }
  }

  setMode(mode: string): any {
    if (mode === '2d') {
      this.viewer.scene.morphTo2D(0);
      this.setMapCanvasSize(1.357, document.body.clientHeight * 0.80);

      setTimeout(() => {
        setTimeout(() => {
          // this.viewer.camera.flyTo({
          //   destination: this.CITY_RECTANGLE,
          // });
        }, 200);
      }, 600);

    } else {
      this.viewer.scene.morphTo3D(0);
      this.setMapCanvasSize(1, document.body.clientHeight * 0.80);

      setTimeout(() => {
        // this.viewer.camera.flyTo({
        //   destination: this.CITY_RECTANGLE,
        // });
      }, 600);
    }

    this.canvasMode = mode;

    setTimeout(() => {
      this.viewer.render();

    }, 1000);
  }

  setMapCanvasSize(ratio, size): void {
    this.canvasSize(size * ratio, size, ratio);
  }

  canvasSize(width, height, ratio): any {
    this.canvasWidth = width;
    this.canvasHeight = height;

    this.map.container.style.height = height + 'px';
    this.map.container.style.width = width + 'px';
    this.map.container.style.transform = `scale(${document.body.clientHeight * 0.80 / height})`;
  }

  optimizePolygon(points, outputMaxPointCount = 100): any {
    if (points.length < 100) {
      return Cesium.Cartesian3.fromDegreesArray(points.flat());
    }

    const polygonOpti: any = [];
    let a = 0;
    for (let i = 0; i < points.length; i ++) {
      a++;
      if (a === Math.round(points.length / outputMaxPointCount)) {
        a = 0;
        polygonOpti.push(points[i]);
      }
    }

    return Cesium.Cartesian3.fromDegreesArray(polygonOpti.flat());
  }

  pickSomePoint(array): any[] {
    const points = [];
    const indice = Math.ceil(array.length / 5);
    let y = 0;
    for (let i = 0; i < array.length; i++) {
      y += 1;

      if (y === indice) {
        y = 0;
        points.push({
          cartesian3: Cesium.Cartesian3.fromDegrees(
            array[i][0], array[i][1]
          ),
          latlng: array[i]
        });
      }
    }

    return points;
  }

  clearAllLayers(): any {
    this.building.entities.removeAll();
    this.polygon.entities.removeAll();
    this.zmi.entities.removeAll();
    this.picto.entities.removeAll();
  }

  normalizeMultipolygon(array): any {
    return array.map(a => {
      return a.map(b => b.map(c => ([c[0], c[1]])));
    });
  }

  drawPolygon = (data) => {
    if (data.features.length === 0) {
      return;
    }

    // On fait passer un Polygon pour un MultiPolygon pour simplifier
    // s'occuper que d'un seul type de polygon au final
    if (data.features[0].geometry.type === 'Polygon') {
      data.features[0].geometry.coordinates = [data.features[0].geometry.coordinates];
    }

    // prevent 3d polygon
    data.features[0].geometry.coordinates = this.normalizeMultipolygon(data.features[0].geometry.coordinates);

    this.CITY_POLYGON = data.features[0].geometry.coordinates;

    this.clearAllLayers();

    this.abstractCityPolygon = this.optimizePolygon(JSON.parse(JSON.stringify(data.features[0].geometry.coordinates)).flat(2));
    const cartesianMultiPolygon = this.multiPolygonToCartesianPolygonHierarchies(data.features[0].geometry.coordinates);

    this.CITY_RECTANGLE = new Cesium.Rectangle.fromCartesianArray(this.abstractCityPolygon);

    cartesianMultiPolygon.forEach(cartesianPolygon => {
      const polyEntity = {
        polygon: {
          hierarchy: cartesianPolygon,
          fill: false,
          outline: true,
          outlineWidth: 50,
        },
      };

      this.polygon.entities.add(polyEntity);
    });

    const rectPos = {
      ne: Cesium.Rectangle.northeast(this.CITY_RECTANGLE),
      nw: Cesium.Rectangle.northwest(this.CITY_RECTANGLE),
      se: Cesium.Rectangle.southeast(this.CITY_RECTANGLE),
      sw: Cesium.Rectangle.southwest(this.CITY_RECTANGLE),
    };

    const values = [
      Cesium.Cartographic.toCartesian({...rectPos.ne, latitude: rectPos.ne.latitude-.01, longitude: rectPos.ne.longitude-.01}),
      Cesium.Cartographic.toCartesian({...rectPos.nw, latitude: rectPos.nw.latitude-.01, longitude: rectPos.nw.longitude+.01}),
      Cesium.Cartographic.toCartesian({...rectPos.sw, latitude: rectPos.sw.latitude+.01, longitude: rectPos.sw.longitude+.01}),
      Cesium.Cartographic.toCartesian({...rectPos.se, latitude: rectPos.se.latitude+.01, longitude: rectPos.se.longitude-.01}),
    ].map(a => ({
      x: a.x,
      y: a.y,
      z: a.z,
    }));

    const polyExtrude = {
      polygon: {
        hierarchy: {
          positions : values,
          holes : cartesianMultiPolygon
        },
        material: Cesium.Color.WHITE.withAlpha(0.3),
      },
    };

    this.polygon.entities.add(polyExtrude);

    return this.CITY_RECTANGLE;
  }
}
